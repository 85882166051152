import { Navigation } from '../../Components'
import { Footer, Contact } from '../../Sections'
import { Container, Accordion, Row } from 'react-bootstrap'
import './style.css'

export const FAQ = () => {


    const faqList = [
        {
            question: "I'm new, can I come to practice?",
            answer: [{ 'description': 'Reach out to our coaches on our contact form to join an upcoming practice.  Review Membership Forms and Payments for more information.', 'link': '/#contact', 'linktext': 'Contact Form' }, { 'link': 'https://docs.google.com/document/d/1bb4Ft0gW9Fg7D4y6SgQdidAmCrB8UxH5/edit?usp=sharing&ouid=115142651878224594095&rtpof=true&sd=true', 'linktext': 'Membership Form' }],

        },

        {
            question: 'Membership Forms and Payments',
            answer: [{ 'link': 'https://docs.google.com/document/d/1bb4Ft0gW9Fg7D4y6SgQdidAmCrB8UxH5/edit?usp=sharing&ouid=115142651878224594095&rtpof=true&sd=true', 'linktext': 'Click here' }],

        },


        {
            question: 'Where does HWOPS practice?',
            answer: [{ 'description': "ƛ̕ax̌ʷadis (Tl' awh- ah - dees) Park", 'link': 'https://goo.gl/maps/oN77V5pSbjxrpd3x5', 'linktext': '7515 NE 175th St, Kenmore, WA 98028' }],
        },

        {
            question: 'Information about HWOPS via Kenmore Water Activities Center.',
            answer: [{ 'linktext': 'Click here', 'link': 'https://www.kenmorewac.org/outrigger/' }],
        },
        {
            question: 'Information about Canoe Clubs via Pacific Northwest Outrigger Racing Canoe Association.',
            answer: [{ 'linktext': 'Click here', 'link': 'https://pnworca.org/directory/' }],
        },

    ]
    const RenderFAQs = (props) => {
        const renderItem = (i) => {
            const formattedAnswers = i?.answer?.map((i) => {
                const formattedLink = (i) => {
                    return (
                        <a href={i.link} target='_blank' rel="noreferrer" >
                            {i.linktext}
                        </a>
                    )
                }
                return <Row>
                    {i.description && i.description}
                    {i.link && formattedLink(i)}
                </Row>
            })


            return (

                < Accordion.Item eventKey="0" >
                    <Accordion.Header>{i.question}</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            {formattedAnswers}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item >
            )
        }
        const rendered = props.data.map((i) => {
            return (
                <Accordion defaultActiveKey={['0']}>
                    {renderItem(i)}
                </Accordion>
            )
        })
        return rendered
    }

    return (
        <div className="pageView">
            <Navigation />
            <Container>
                <h1>FAQs</h1>
                <RenderFAQs data={faqList} />
            </Container>
            <Contact />
            <Footer />
        </div>
    )
}

